import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './layout/header/header.component';

import { MatMenuModule } from '@angular/material/menu';
import { MatRippleModule } from '@angular/material/core';
import { MatBadgeModule } from '@angular/material/badge';

import { GalleryDialogModule } from './pages/private/gallery-dialog/gallery-dialog.module';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CodeEditorModule } from '@ngstack/code-editor';

import localeCo from '@angular/common/locales/es-CO';
import { registerLocaleData } from '@angular/common';
import {InterceptorService} from './services/interceptor.service';
registerLocaleData(localeCo, 'es-CO');

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatMenuModule,
    MatRippleModule,
    MatBadgeModule,
    GalleryDialogModule,
    CKEditorModule,
    CodeEditorModule.forRoot(),
  ],
  providers: [
      { provide: LOCALE_ID, useValue: 'es-CO' },
      { provide:HTTP_INTERCEPTORS, useClass:InterceptorService,multi:true }
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
