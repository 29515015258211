import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  constructor() { }

  public encryptObject(obj: object):string{
    let objectEncrypt = btoa(JSON.stringify(obj));
    return objectEncrypt;
  }

  public decryptObject(obj:any):object{
    let decryptedObject = JSON.parse(JSON.parse(decodeURIComponent(escape(atob(obj)))));
    return decryptedObject;
  }
}
