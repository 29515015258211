import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';

import {AuthService} from '../../services/auth.service';
import {HttpService} from '../../services/http.service';
import {SocketService} from '../../services/socket.service';

import {environment} from '../../../environments/environment';
import * as moment from 'moment';
import {MenuService} from '../../services/menu.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
    public user: any = {};
    public ntfQty = 0;
    public onPrincipal = false;
    public notifications: any = [];
    private stateColors: any = {
        '5e068c81d811c55eb40d14d0': 'bg-publish',
        '5e068d1cb81d1c5f29b62974': 'bg-approved',
        '5e068d1cb81d1c5f29b62975': 'bg-reviewed',
        '5e068d1cb81d1c5f29b62976': 'bg-toReview',
        '5e068d1cb81d1c5f29b62977': 'bg-draft'
    };
    public STORAGE_URL = environment.STORAGE_FILES;
    public marketing: boolean;
    public sidevarMenu = true;
    public isMobileLayout = false;
    public marketingToggle = false;
    public showButtons = false;

    constructor(
        private router: Router,
        private auth: AuthService,
        private http: HttpService,
        private socket: SocketService,
        private menuService: MenuService,
    ) {
        this.auth.user.subscribe((user) => {
            this.user = user;
        });
        this.startToListenRouter(this.router);
        this.startToListenSockets();
        this.marketing = this.auth.isMarketing();

        this.menuService.$listenActions.subscribe((event) => {
            if (event.action === 'hide') {
                this.sidevarMenu = true;
            }
        });
    }

    ngOnInit() {
        moment.locale('es'); // Set locale lang for momentJs
        this.getNotifications();
        this.getCountNotifications();

        this.auth.user.subscribe((user) => {
            this.user = user;
        });
    }

    public getUserImage() {
        const files = this.user.files;
        const profileImage = files && files.length ? files.find(e => e.key === 'profile-image') : null;

        if (!profileImage) {
            return '/assets/images/user/user.png';
        }

        return this.STORAGE_URL + profileImage.fileName;
    }

    private getNotifications(): void {
        this.http.get({
            path: `notifications`,
            data: {
                order: 'id DESC',
                include: [
                    {relation: 'emitter', scope: {fields: ['name']}},
                    {relation: 'report', scope: {fields: ['name', 'stateId']}}
                ],
                where: {
                    ownerId: this.user.id,
                    type: 'report-reviewer',
                    action: {inq: ['created', 'refused', 'approved', 'published']},
                    readed: false
                },
                limit: 50
            },
            encode: true
        }).subscribe((response: any) => {
            if ('body' in response) {
                response.body.map(notification => {
                    this.processNotification(notification);
                });
            }
        });
    }

    private getCountNotifications(): void {
        this.http.get({
            path: `notifications/count?where=`,
            data: {
                ownerId: this.user.id,
                readed: false,
                type: 'report-reviewer',
                action: {inq: ['created', 'refused', 'approved', 'published']},
            }
        }).subscribe((response: any) => {
            if ('body' in response) {
                this.ntfQty = response.body.count;
            }
        });
    }

    private startToListenSockets() {
        this.socket.start().subscribe(() => {

            // If listen a new notification for be processed and get qty of notifications unreaded
            this.socket.on('notification').subscribe((response) => {
                this.processNotification(response, true);
                this.getCountNotifications();
            });

            // If listen when a notification was readed, get all notifications again and and their qty
            this.socket.on('readed').subscribe(() => {
                this.getNotifications();
                this.getCountNotifications();
            });
        });
    }

    private processNotification(item: any, isSocket: boolean = false) {
        if (!item.report || !item.emitter) {
            return;
        }

        const actions = ['created', 'refused', 'approved', 'published'];
        if (item.type !== 'report-reviewer' && actions.indexOf(item.action) === -1) {
            return;
        }

        const timeFromNow: string = moment(item.updatedAt).fromNow();
        const txtDescription: string = item.text
            .replace(/{{emitter_name}}/, item.emitter.name)
            .replace(/{{report_name}}/, item.report.name);
        const notf: any = {
            id: item.id,
            type: item.type,
            subject: item.subject,
            text: txtDescription,
            timeAgo: timeFromNow,
            readed: item.readed,
            reportId: item.reportId
        };

        if (item.type !== 'report-comment' && 'report' in item && 'stateId' in item.report) {
            notf.bgColor = this.stateColors[item.reportStateId] || 'bg-default';
        }

        if (isSocket) {
            this.notifications = [notf].concat(this.notifications);
        } else {
            this.notifications.push(notf);
        }
    }

    private startToListenRouter(router: Router) {
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const pageName = event.url.split('/')[2];
                this.onPrincipal = pageName === 'principal';
            }
        });
    }

    public logout() {
        this.auth.logout().subscribe((response: boolean) => {
            if (response) {
                this.router.navigate(['login']);
            }
        });
    }

    isOpened(evt: any) {
        const headerEl = document.getElementById('notificationHeader2');
        if (!headerEl) {
            return;
        }
        const ntContainer = headerEl.parentElement;
        ntContainer.classList.add('notifications');
    }

    public checkNotifications(notificationId: string, cb: any) {
        const dataFilter = encodeURI(JSON.stringify({id: notificationId}));
        this.http.patch({
            path: `notifications/read?filter=${dataFilter}`,
            data: {readed: true}
        }).subscribe(() => {
            if (cb) {
                cb();
            }
        });
    }

    openNotf(notification, readed?) {
        const notificationId = notification.id;
        const reportId = notification.reportId;

        this.getCountNotifications();
        this.notifications.filter((a) => {
            if (a.reportId === reportId) {
                a.readed = true;
            }
            return true;
        });

        this.checkNotifications(notificationId, () => {
            const queryParams: any = {};
            if (notification.type === 'report-comment') {
                queryParams.showComments = true;
            }
            this.isMobileLayout = false;
            this.router.navigate(['app/board', reportId], {queryParams});
        });
    }

    public gotoTo() {
        this.router.navigate(['app/users']);

    }

    openNav() {
        this.sidevarMenu = false;
        this.menuService.emit('show');
    }

    closeNav() {
        this.sidevarMenu = true;
        this.menuService.emit('hide');
    }
}
